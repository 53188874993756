import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn btn--large", { 'btn--active': _ctx.isOpened }])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["btn-label", { 'btn-label--active': _ctx.isOpened }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpened = !_ctx.isOpened))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.unitData[_ctx.getActiveUnit]), 1),
      _createVNode(_component_icon, {
        class: "icon icon--small-ultra icon--margin-left",
        src: `icons/arrow-down-active.svg`,
        alt: 'Arrow'
      }, null, 8, ["src"])
    ], 2),
    _createVNode(_component_dropdown, {
      dropRight: true,
      isOpened: _ctx.isOpened,
      data: _ctx.unitData,
      activeItem: _ctx.unitData[_ctx.getActiveUnit],
      onOnSelect: _ctx.setActive
    }, {
      item: _withCtx(({item}) => [
        _createElementVNode("span", null, _toDisplayString(item), 1)
      ]),
      _: 1
    }, 8, ["isOpened", "data", "activeItem", "onOnSelect"])
  ], 2)), [
    [_directive_click_outside, _ctx.closeDropdown]
  ])
}