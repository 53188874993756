import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item = _resolveComponent("list-item")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["list", _ctx.setListClass()])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (d, i) => {
      return (_openBlock(), _createBlock(_component_list_item, {
        class: _normalizeClass(_ctx.setItemClasses(i)),
        key: i,
        onClick: ($event: any) => (_ctx.setActive(i))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(d), 1)
        ]),
        _: 2
      }, 1032, ["class", "onClick"]))
    }), 128))
  ], 2))
}