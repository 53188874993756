
import { Options, Vue } from "vue-class-component";
import { getBreakpoint } from "@/utils";
import { settingsModule } from "@/store";
import Dropdown from "@/components/ui/BaseDropdown/index.vue";
import List from "@/components/ui/BaseList/index.vue";
import UnitsButton from "./BaseButtonUnits/index.vue";

@Options({
  name: "UnitList",
  components: {
    Dropdown,
    List,
    UnitsButton,
  },
})
export default class UnitList extends Vue {
  private isDesktop: boolean = ["lg", "xl"].includes(getBreakpoint());

  private get getUnits(): Array<string> {
    return settingsModule.getUnits;
  }

  private get getUnit(): number {
    return settingsModule.getUnit;
  }

  private resizeHandler() {
    this.isDesktop = ["lg", "xl"].includes(getBreakpoint());
  }

  private onActiveIndexChange(val: number): void {
    settingsModule.Unit(val);
  }

  beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
