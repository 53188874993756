
import { Options, Vue } from "vue-class-component";
import ListItem from "./BaseListItem.vue";

@Options({
  name: "lists",
  components: {
    ListItem,
  },
  props: {
    horizontally: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
      default: [],
    },
    active: {
      type: Number,
      default: 0,
    }
  },
})
export default class Lists extends Vue {
  readonly horizontally = false;
  readonly active!: number;
  private isActive = this.active;

  get isHorizontally(): boolean {
    return this.horizontally;
  }

  private setItemClasses(index: number): Array<string> {
    let classes: Array<string> = [];

    if (index === this.isActive) {
      classes = classes.concat(["list-item--active"]);
    }

    return classes;
  }

  private setListClass(): Array<string> {
    let classes: Array<string> = [];

    if (this.isHorizontally) {
      classes = classes.concat(["list--horizontally"]);
    }

    return classes;
  }

  private setActive(index: number): void {
    this.isActive = index;
    this.$emit("index", index);
  }
}
