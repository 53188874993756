import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnitsButton = _resolveComponent("UnitsButton")!
  const _component_List = _resolveComponent("List")!

  return (!_ctx.isDesktop)
    ? (_openBlock(), _createBlock(_component_UnitsButton, {
        key: 0,
        "unit-data": _ctx.getUnits
      }, null, 8, ["unit-data"]))
    : (_openBlock(), _createBlock(_component_List, {
        key: 1,
        class: _normalizeClass('list--unit'),
        active: _ctx.getUnit,
        data: _ctx.getUnits,
        onIndex: _ctx.onActiveIndexChange
      }, null, 8, ["active", "data", "onIndex"]))
}