import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bc37e90"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "list",
  ref: "list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item = _resolveComponent("list-item")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "dropdown",
    class: _normalizeClass(["dropdown", [
      { 'dropdown--opened': _ctx.isOpened },
      { 'dropdown--right': _ctx.dropRight },
      { 'dropdown--left': _ctx.dropLeft },
      { 'dropdown--top': _ctx.dropTop },
    ]])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (d) => {
        return (_openBlock(), _createBlock(_component_list_item, {
          onClick: ($event: any) => (_ctx.$emit('on-select', d)),
          key: d.id || d,
          class: _normalizeClass({active: (d.id || d) === _ctx.activeItem})
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "item", { item: d }, undefined, true)
          ]),
          _: 2
        }, 1032, ["onClick", "class"]))
      }), 128))
    ], 512)
  ], 2)), [
    [_vShow, _ctx.showDropdown]
  ])
}