
import {Options, Vue} from "vue-class-component";
import ListItem from "./DropdownItem.vue";
import {Keys} from "@/types";
import {useMq} from "vue3-mq";

const ANIMATION_DELAY = 150;

@Options({
  name: "Dropdown",
  components: {
    ListItem,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    activeItem: {
      type: String
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    dropRight: {
      type: Boolean,
      default: false,
    },
    dropLeft: {
      type: Boolean,
      default: false,
    },
    dropTop: {
      type: Boolean,
      default: false,
    },
    displayHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isOpened(val) {
      if (val) {
        const iconWidth = 40;
        const paddings = 48;
        let textWidth = 0;

        for (const item of this.$refs.list.children) {
          const [child1, child2] = item.children;
          const element = document.createElement("div");

          element.style.display = "inline-block";
          element.innerHTML = child2 ? child2.innerText : child1.innerText;

          document.body.appendChild(element);

          if (element.offsetWidth > textWidth) {
            textWidth = element.offsetWidth;
          }

          document.body.removeChild(element);
        }

        const itemWidth = ['lg', 'xl', 'xxl'].includes(this.mq.current)
            ? textWidth + iconWidth + paddings
            : textWidth + iconWidth + paddings * 0.75;

        this.$refs.dropdown.style.setProperty(
            "--dropdown-width",
            `${itemWidth.toString()}px`
        );

        setTimeout(() => (this.showDropdown = val), ANIMATION_DELAY);
      } else {
        this.showDropdown = val;

        this.emitter.emit("properties-filter-change", this.type === Keys.Part);
        this.$refs.dropdown.style.setProperty("--dropdown-width", "");
      }
    },
  }
})
export default class Dropdown extends Vue {
  $refs!: {
    list: HTMLFormElement;
    dropdown: HTMLFormElement;
  };
  private mq = useMq();
  private showDropdown = false;
}
