
import { Options, Vue } from "vue-class-component";
import { ClickOutside } from "@/directives";
import { settingsModule } from "@/store";
import { Units } from "@/types";
import Dropdown from "@/components/ui/BaseDropdown/index.vue";
import Icon from "@/components/ui/BaseIcons/Icon.vue";

@Options({
  name: "UnitsButton",
  directives: {
    "click-outside": ClickOutside,
  },
  props: {
    unitData: {
      type: Array,
      default: [],
    },
  },
  components: {
    Dropdown,
    Icon,
  },
})
export default class PropertiesBtn extends Vue {
  private isOpened = false;
  private unitData!: any;

  get getActiveUnit(): Units {
    return settingsModule.getUnit || Units.Cm;
  }

  protected setActive(unit: string): void {
    const index = this.unitData.findIndex((u: string) => u === unit);

    settingsModule.Unit(index);

    this.closeDropdown();
  }

  private closeDropdown(): void {
    this.isOpened = false;
  }
}
